<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>平台管理</el-breadcrumb-item>
      <el-breadcrumb-item>活动管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体卡片视图区域 -->
    <el-card class="box-card">
      <!-- 上栅格布局  -->
      <el-row :gutter="10">
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
<!--          <el-select v-model="selectValue" filterable clearable placeholder="全部">-->
<!--            <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.selectValue"-->
<!--                :label="item.label"-->
<!--                :value="item.selectValue"-->
<!--                :change="selectChange()"-->
<!--            >-->
<!--              <span style="float: left">{{ item.label }}</span>-->
<!--              &lt;!&ndash;                              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.selectValue }}</span>&ndash;&gt;-->
<!--            </el-option>-->
<!--          </el-select>-->
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-input placeholder="请输入内容" v-model="DevicesListInfo.query" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="getDevicesList"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addDialog =true" v-has="'POST_HuodongPermission'">添加</el-button>
          <el-button type="success" icon="el-icon-refresh-right" @click="update()">刷新</el-button>
<!--          <el-button type="warning" icon="el-icon-refresh-right" @click="bindDialog = true">绑定</el-button>-->
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">

        </el-col>
      </el-row>

      <!-- 下渲染列表区域 -->
      <transition name="el-fade-in-linear">
        <el-table :data="DevicesList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="编号" sortable prop="id"></el-table-column>
          <!--          <el-table-column label="验证合法" prop="isValid"></el-table-column>-->
          <el-table-column label="创建时间" sortable prop="createTime" :formatter="dateFormat"></el-table-column>
          <el-table-column label="最后在线时间" sortable prop="lastUpdateTime" :formatter="dateFormat"></el-table-column>
          <el-table-column label="活动名称"  prop="huodongmingcheng" ></el-table-column>

          <el-table-column label="价格"  prop="jiage" ></el-table-column>
          <el-table-column label="赠送金额"  prop="zengsongjine" ></el-table-column>



<!--          <el-table-column-->
<!--              label="用户名"-->
<!--              prop="username"-->
<!--              :filters="[{text: 'admin', value: 'admin'}, {text: 'admin1', value: 'admin1'}, {text: 'admin2', value: 'admin2'}, {text: 'admin3', value: 'admin3'}]"-->
<!--              :filter-method="filterHandler"-->
<!--          ></el-table-column>-->
          <!--          <el-table-column label="Role"  prop="Role" ></el-table-column>-->
          <!--          <el-table-column label="创建协调时间" sortable prop="isEditPingtaisetting" :formatter="dateFormat"></el-table-column>-->
          <!--          <el-table-column label="修改协调时间" sortable prop="UtcModified" :formatter="dateFormat"></el-table-column>-->
          <el-table-column label="操作" width="310px">
            <template v-slot="scope">
              <!--             <pre> {{scope.row}}</pre>-->

              <div>
                <el-link class="linkStyle" :underline="false" @click="showCheckDialog(scope.row.id)" v-has="'GET_HuodongPermission'">查看</el-link>
                <el-link class="linkStyle" :underline="false" @click="showEditDialog(scope.row.id)" v-has="'PUT_HuodongPermission'">编辑</el-link>
              </div>


            </template>
          </el-table-column>
        </el-table>
      </transition>

      <!-- 分页区域 -->
      <div>
        <!--       <el-button @click="previousPage">上一页</el-button>-->
        <!--        <el-button @click="nextPage">下一页</el-button>-->
      </div>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="DevicesListInfo.CurrentPage"
          :page-sizes="[5, 10]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Total">
      </el-pagination>
    </el-card>

    <!-- 添加，对话框 -->
    <el-dialog
        title="添加"
        :visible.sync="addDialog"
        width="50%"
        @close="addDialogClosed"
    >
      <!-- 内容主题区域 -->
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef"  label-width="150px">

        <!--        <el-form-item label="设备类别" prop="deviceType">-->
        <!--          <el-select style="width: 80%" v-model="addForm.deviceType" placeholder="请选择">-->
        <!--            <el-option label="吹风机" value="chuifengji"></el-option>-->
        <!--            <el-option label="洗衣机" value="xiyiji"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!--      <span>Selected: {{ addForm.deviceType }}</span>-->

        <el-form-item label="活动名称" prop="huodongmingcheng" >
          <el-input style="width: 80%" v-model.number="addForm.huodongmingcheng" placeholder="请填写"></el-input>
        </el-form-item>


        <el-form-item label="价格" prop="jiage" >
          <el-input style="width: 80%" v-model.number="addForm.jiage" placeholder="请填写"></el-input>
        </el-form-item>

        <el-form-item label="赠送金额" prop="zengsongjine" >
          <el-input style="width: 80%" v-model.number="addForm.zengsongjine" placeholder="请填写"></el-input>
        </el-form-item>

        <el-form-item label="项目" prop="xiangmu" >
          <el-input style="width: 80%" v-model.number="addForm.xiangmu" placeholder="请填写" :disabled="true"></el-input>
        </el-form-item>

        <!--        <el-form-item label="手机号" prop="sjh" >-->
        <!--          <el-input style="width: 80%" v-model="addForm.sjh" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->

        <el-form-item label="平台" prop="pingtai" >
          <el-input style="width: 80%"  v-model="addForm.pingtai" placeholder="请填写" :disabled="true"></el-input>
        </el-form-item>



        <!--        <el-form-item label="到期时间" prop="daoqishijian" >-->
        <!--          <el-input style="width: 80%"   v-model="addForm.daoqishijian" placeholder="例：2022-06-10 T 10:42:29"></el-input>-->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="到期时间" required>-->
        <!--          <el-col :span="11">-->
        <!--            <el-form-item prop="daoqishijian">-->
        <!--              <el-date-picker-->
        <!--                  value-format="yyyy-MM-dd"-->
        <!--                  range-separator="至"-->
        <!--                  start-placeholder="开始日期"-->
        <!--                  end-placeholder="结束日期" type="date"-->
        <!--                  placeholder="选择日期"-->
        <!--                  v-model="addForm.daoqishijian"-->
        <!--                  style="width: 100%;"></el-date-picker>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--          &lt;!&ndash;          <el-col class="line" :span="2">-</el-col>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <el-col :span="11">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <el-form-item prop="daoqishijian">&ndash;&gt;-->
        <!--          &lt;!&ndash;              <el-time-picker placeholder="选择时间" v-model="addForm.daoqishijian" style="width: 100%;"></el-time-picker>&ndash;&gt;-->
        <!--          &lt;!&ndash;            </el-form-item>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </el-col>&ndash;&gt;-->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="管理员名称" prop="adminUser" >-->
        <!--          <el-input style="width: 80%" v-model="addForm.adminUser" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="管理员密码" prop="adminPassword" >-->
        <!--          <el-input style="width: 80%" v-model="addForm.adminPassword" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="管理员手机号" prop="adminSjh" >-->
        <!--          <el-input style="width: 80%" v-model="addForm.adminSjh" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->






      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
    <el-button @click="addDialog = false">取 消</el-button>
    <el-button type="primary" @click="addDevice">确 定</el-button>
  </span>
    </el-dialog>

    <!-- 编辑，对话框 -->
    <el-dialog
        title="编辑"
        :visible.sync="editDialog"
        width="50%"
        @close="editDialogClosed"
    >
      <!-- 内容主题区域 -->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef"  label-width="80px">

        <!--        <el-form-item label="验证合法"  prop="" >-->
        <!--          <el-input style="width: 80%"  :disabled="true" v-model="editForm.isValid" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="名称" prop="huodongmingcheng" >
          <el-input style="width: 80%" v-model="editForm.huodongmingcheng" placeholder="请填写"></el-input>
        </el-form-item>

        <el-form-item label="价格" prop="jiage" >
          <el-input style="width: 80%" v-model="editForm.jiage" placeholder="请填写"></el-input>
        </el-form-item>

        <el-form-item label="项目" prop="xiangmu" >
          <el-input style="width: 80%"  v-model="editForm.xiangmu" placeholder="请填写"></el-input>
        </el-form-item>

        <el-form-item label="赠送" prop="zengsongjine" >
          <el-input style="width: 80%"  v-model="editForm.zengsongjine" placeholder="请填写"></el-input>
        </el-form-item>


        <!--        <el-form-item label="到期时间" prop="daoqishijian" >-->
        <!--          <el-input style="width: 80%"  v-model="editForm.daoqishijian" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="到期时间" required>-->
        <!--          <el-col :span="11">-->
        <!--            <el-form-item prop="daoqishijian">-->
        <!--              <el-date-picker-->
        <!--                  value-format="yyyy-MM-dd"-->
        <!--                  range-separator="至"-->
        <!--                  start-placeholder="开始日期"-->
        <!--                  end-placeholder="结束日期" type="date"-->
        <!--                  placeholder="选择日期"-->
        <!--                  v-model="editForm.daoqishijian"-->
        <!--                  style="width: 100%;"></el-date-picker>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--          &lt;!&ndash;          <el-col class="line" :span="2">-</el-col>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <el-col :span="11">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <el-form-item prop="daoqishijian">&ndash;&gt;-->
        <!--          &lt;!&ndash;              <el-time-picker placeholder="选择时间" v-model="addForm.daoqishijian" style="width: 100%;"></el-time-picker>&ndash;&gt;-->
        <!--          &lt;!&ndash;            </el-form-item>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </el-col>&ndash;&gt;-->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="编辑平台"  prop="" >-->
        <!--          <el-input style="width: 80%" :disabled="true"  v-model="editForm.isEditPingtaisetting" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->




      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialog = false">取 消</el-button>
    <el-button type="primary" @click="editInfo">确 定</el-button>
  </span>
    </el-dialog>

    <!-- 查看，对话框 -->
    <el-dialog
        title="详情"
        :visible.sync="checkDialog"
        width="50%"

    >

      <!--  展示表格样式  -->
      <el-table :show-header="false"
                :data="tableData1()"
                border
                style="width: 100%; margin-top: 20px"
      >


        <el-table-column label="1" width="100px" prop="column1"></el-table-column>
        <el-table-column label="2" prop="column2"></el-table-column>
        <el-table-column label="3" width="100px" prop="column3"></el-table-column>
        <el-table-column label="4" prop="column4"></el-table-column>

      </el-table>
      <!--      <el-image :src="editForm.qrCode" style="width: 150px; height: 150px"></el-image>-->

      <!--  底部按钮  -->
      <span slot="footer" class="dialog-footer">
    <el-button @click="checkDialog = false">取 消</el-button>
    <el-button type="primary" @click="checkDialog = false">确 定</el-button>
  </span>
    </el-dialog>

    <!-- 绑定，对话框 -->
    <el-dialog
        title="绑定"
        :visible.sync="bindDialog"
        width="50%"
        @close="bindDialogClosed"
    >
      <!-- 内容主题区域 -->
      <el-form :model="addForm" :rules="bindFormRules" ref="bindFormRef"  label-width="150px">


        <el-form-item label="设备id" prop="id" >
          <el-input style="width: 80%" v-model.number="addForm.id" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="项目id" prop="xiangmu" >
          <el-input style="width: 80%" v-model.number="addForm.xiangmu" placeholder="请填写"></el-input>
        </el-form-item>

        <!--        <el-form-item label="手机号" prop="sjh" >-->
        <!--          <el-input style="width: 80%" v-model="addForm.sjh" placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->

        <el-form-item label="平台" prop="pingtai" >
          <el-input style="width: 80%"  v-model="addForm.pingtai" placeholder="请填写" :disabled="true"></el-input>
        </el-form-item>

      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
    <el-button @click="bindDialog = false">取 消</el-button>
    <el-button type="primary" @click="bind">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>

//导入moment插件
import moment from 'moment';

//导入axios
import axios from "axios";
export default {
  name: "Device",

  props: ['value'],
  data() {

    //验证手机号是否合法
    let isTelphone= (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
            callback(new Error("手机号码格式有误"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入正确的电话号码"));
        }
      }
    };

    return{

      //下拉筛选数据源
      options: [{
        selectValue: '选项1',
        label: '管理员'
      }, {
        selectValue: '选项2',
        label: '用户'
      },

      ],
      selectValue: '',

      howWidth: 8,

      //获取列表单数据（get请求）
      DevicesListInfo: {
        // //添加类型
        // deviceType: "chuifengji",
        // //当前页数
        CurrentPage: 1,
        // //当前每页显示多少条数据
        // PageSize: 10,

        query: '',

      },

      //添加设备表单数据（post请求）
      addForm: {


        pingtai:window.sessionStorage.getItem('pingtaiId'),

        huodongmingcheng:'',
        jiage:0,
        zengsongjine:0,
        xiangmu:window.sessionStorage.getItem('xiangmuId'),

        id:0,


      },


      //编辑设备表单数据 (put请求）
      editForm: {

      },

      //后端请求的，编辑设备表单数据
      // checkForm: {
      //   DeviceName:"",
      //   DeviceSecret:"",
      //   GmtActive:"",
      //   GmtCreate:"",
      //   GmtOnline:"",
      //   IotId:"",
      //   NodeType:0,
      //   Owner:true,
      //   ProductKey:"",
      //   ProductName:"",
      //   Region:"",
      //   Status:"",
      //   UtcActive:"",
      //   UtcCreate:"",
      //   UtcOnline:"",
      //   qrCode:"",
      // },






      // 设置并保存设备参数信息的数据
      DevicesList: [],
      Total: 0,

      //控制添加设备对话框显示与隐藏
      addDialog: false,

      //控制编辑设备对话框显示与隐藏
      editDialog: false,

      //控制查看设备对话框显示与隐藏
      checkDialog: false,

      //控制绑定设备对话框显示与隐藏
      bindDialog: false,

      //添加表单验证规则
      addFormRules: {

        jiage:[
          { required: true, message: '数量不能为空'},
          { type: 'number', message: '必须为数字值'},
          { pattern: /^([1-9]|[1-9][0-9]|[1-9][0-9][0-9])$/, message: '范围在1-999', trigger: 'blur'},
        ],

        zengsongjine:[
          { required: true, message: '数量不能为空'},
          { type: 'number', message: '必须为数字值'},
          { pattern: /^(1|[1-9]\d?|100)$/, message: '范围在1-100', trigger: 'blur'},
        ],

        huodongmingcheng: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        pingtai: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        xiangmu: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],

        sjh: [
          { required: true, message: '不可为空', trigger: 'change' },
          { validator: isTelphone, trigger: "blur" }
        ],
        daoqishijian: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        adminUser: [
          { required: true, message: '不可为空', trigger: 'change' },
          { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' }
        ],
        adminPassword: [
          { required: true, message: '不可为空', trigger: 'change' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        adminSjh: [
          { required: true, message: '不可为空', trigger: 'change' },
          { validator: isTelphone, trigger: "blur" }
        ],

      },


      //编辑表单验证规则
      editFormRules: {
        huodongmingcheng: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        jiage: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        sjh: [
          { required: true, message: '不可为空', trigger: 'change' },
          { validator: isTelphone, trigger: "blur" }
        ],
        xiangmu: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        zengsongjine: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],

      },

      //绑定表单验证规则
      bindFormRules: {
        id: [
          { required: true, message: '不可为空', trigger: 'change' },
          { type: 'number', message: '必须为数字值'}
        ],
        xiangmu: [
          { required: true, message: '不可为空', trigger: 'change' },
          { type: 'number', message: '必须为数字值'}
        ],

      },


      //展示对话框数据（编辑对话框数据源）
      tableData1() {
        return [
          {
            column1:'ID',
            column2:this.editForm.id,
            column3:'创建时间',
            column4:this.editForm.createTime,
          },
          {
            column1:'上次更新时间',
            column2:this.editForm.lastUpdateTime,
            column3:'用户名',
            column4:this.editForm.username,
          },
          {
            column1:'地址',
            column2:this.editForm.dizhi,
            column3:'手机号',
            column4:this.editForm.sjh,
          },
          {
            column1:'到期时间',
            column2:this.editForm.daoqishijian,
            column3:'',
            column4:this.editForm.isEditPingtaisetting,
          },

        ]
      },



    }
  },
  created() {
    this.getDevicesList()
  },
  methods: {

    //格式化函数（布尔值)
    booleans(row,column) {
      // row[column.property] 能读取到该行该列的数据
      const boolean= row[column.property];
      let string = null;
      if(boolean == true) {
        string ='是';
      }else if(boolean == false) {
        string ='否';
      }
      return string;
    },


    //状态格式化函数（设备状态）
    changeStatus(row,column) {
      // row[column.property] 能读取到该行该列的数据
      const DeviceStatus= row[column.property];
      let string = null;
      if(DeviceStatus == 'ONLINE') {
        string ='设备在线';
      }else if(DeviceStatus == 'OFFLINE') {
        string ='设备离线';
      }else if(DeviceStatus == 'UNACTIVE') {
        string ='设备未激活';
      }else if(DeviceStatus == 'DISABLE') {
        string ='设备已禁用';
      }
      return string;
    },

    //日期格式化方法
    dateFormat(row, column) {
      // console.log(row, column)
      const date = row[column.property]
      if (date === undefined) {
        return ''
      }
      // 这里的格式根据需求修改
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },


    //获取列表参数，发起get请求
    async getDevicesList(page) {
      // console.log(page)
      if(page == undefined) {
        page = 1
      }
      await axios({
        // headers: {
        //   "Authorization": "jwt" + " " + sessionStorage.getItem("token"),
        //   "Content-Type": "application/json"
        // },
        method: "get",
        url: "/iotplatform/huodong/",
        //请求设备列表参数对象
        // data: this.DevicesListInfo,
        params: { page: page,xiangmu:window.sessionStorage.getItem('xiangmuId'), },
      }).then((res) => {
        // console.log(res);
        //获取请求后的数据重新赋值
        const DeviceInfo = (res.data.result.results);
        const Total = (res.data.result.count);
        const PageSize = (res.data.result.PageSize);
        //

        console.log(DeviceInfo);
        // //将请求的数据赋值给data中数据
        this.DevicesList = DeviceInfo;
        this.PageSize = PageSize;
        this.Total = Total;
        //
        //
        // console.log(DeviceInfo)
        //若status不是200,返回错误消息
        if(res.status !== 200) {
          return this.$message.error('获取列表失败！')
        }
      });

    },

    //发起添加，发起post请求
    addDevice() {
      this.$refs.addFormRef.validate(async valid => {
        //console.log(valid);
        if (!valid) return;
        //发起添加用户网络请求
        const {data : res} = await this.$http.post('/iotplatform/huodong/',this.addForm)

        if(res.code !== 1) {
          this.$message.error(res.msg);
        } else if(res.code ==1) {
          this.$message.success(res.msg);
        }

        //添加对话框关闭
        this.addDialog = false;

        //重新获取设备列表数据
        await this.getDevicesList()


      });
    },

    //展示编辑对话框,发起get请求
    async showEditDialog(id) {
      console.log(id)

      const {data:res} = await this.$http.get('/iotplatform/huodong/'+id+'/')
      console.log(res)
      if(res.code !== 1) {
        this.$message.error(res.msg);
      }



      this.editForm = res.result
      //编辑对话框打开
      this.editDialog = true

    },

    //编辑信息并提交，发起put请求
    editInfo() {
      this.$refs.editFormRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return

        //发起编辑信息提交 请求
        const {data:res} = await this.$http.put('/iotplatform/huodong/'+this.editForm.id+'/',{

          huodongmingcheng: this.editForm.huodongmingcheng,

          jiage: this.editForm.jiage,

          xiangmu: this.editForm.xiangmu,

          zengsongjine: this.editForm.zengsongjine,



          pingtaimingcheng: this.editForm.pingtaimingcheng,
          pingtai: window.sessionStorage.getItem('pingtaiId'),
          kefudianhua: this.editForm.kefudianhua,
          daoqishijian: this.editForm.daoqishijian,
          isEditPingtaisetting: this.editForm.isEditPingtaisetting,


        })

        if(res.code !== 1) {
          this.$message.error(res.msg);
        }


        //编辑对话框关闭
        this.editDialog = false
        this.$message.success(res.msg);
      })
    },

    //展示查看对话框,发起get请求
    async showCheckDialog(id) {
      // console.log(id)
      await this.$router.push({ path:'/activeDetail', query:{id:id} })
      // const {data:res} = await this.$http.get('/device/chuifengji/'+id+'/')
      // console.log(res)
      // if(res.code !== 1) {
      //   this.$message.error(res.msg);
      // }
      //
      //
      //
      // this.editForm = res.result
      //
      // //详情对话框打开
      // this.checkDialog = true

    },


    //监听 pageSize 改变事件
    handleSizeChange(newSize) {
      console.log(newSize);
      this.PageSize = newSize;
    },

    //监听 页码值 改变事件 ,并发起请求
    async handleCurrentChange(newPage) {
      console.log(newPage);
      await this.getDevicesList(newPage)
    },



    //列表刷新功能
    update(){
      this.$router.go(0)
      this.getDevicesList()
    },


    //监听 添加对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },

    //监听 编辑对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },

    //监听 绑定对话框的关闭事件
    bindDialogClosed() {
      this.$refs.bindFormRef.resetFields();
    },

    //筛选
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },


    //下拉选择器
    selectChange() {

    },


    //绑定设备
    bind () {
      this.$refs.bindFormRef.validate(async valid => {
        // console.log(valid);
        if (!valid) return;
        //发起添加用户网络请求
        const {data : res} = await this.$http.post('/device/chuifengji/binding/',this.addForm)

        if(res.code !== 1) {
          this.$message.error(res.msg);
        } else if(res.code ==1) {
          this.$message.success(res.msg);
        }



        //对话框关闭
        this.bindDialog = false;

        //重新获取设备列表数据
        await this.getDevicesList()


      });
    },

  },


}
</script>

<style lang="less" scoped>

.linkStyle {
  margin: 5px;
}

</style>
